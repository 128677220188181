<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
<!--      <CIcon name="logo" height="48" alt="Logo"/>-->
      <img src="img/brand/main_logo_small.png" class="">
    </CHeaderBrand>

    <CMenu/>

    <CHeaderNav>
            <CHeaderNavItem class="px-3">
              <CSelect
                class="mt-3"
                :options="langs"
                :value="locale"
                @update:value="selectLocale"
              />
            </CHeaderNavItem>

      <CHeaderNavItem class="px-3">
        <v-idle
          @idle="onidle"
          @remind="onremind"
          :loop="true"
          :reminders="[15, 30]"
          :wait="1"
          :duration="1200"/>
      </CHeaderNavItem>


<!--      <CHeaderNavItem class="px-3">-->
<!--        <button-->
<!--          @click="() => $store.commit('toggle', 'darkMode')"-->
<!--          class="c-header-nav-btn"-->
<!--        >-->
<!--          <CIcon v-if="$store.state.darkMode" name="cil-sun"/>-->
<!--          <CIcon v-else name="cil-moon"/>-->
<!--        </button>-->
<!--      </CHeaderNavItem>-->
<!--      <TheHeaderDropdownNotif/>-->
<!--      <TheHeaderDropdownTasks/>-->
<!--      <TheHeaderDropdownMssgs/>-->
      <TheHeaderDropdownAccnt/>
<!--      <CHeaderNavItem class="px-3">-->
<!--        <button-->
<!--          in-header-->
<!--          class="c-header-nav-btn"-->
<!--          @click="$store.commit('toggle', 'asideShow')"-->
<!--        >-->
<!--          <CIcon size="lg" name="cil-applications-settings" class="mr-2"/>-->
<!--        </button>-->
<!--      </CHeaderNavItem>-->
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderDropdownNotif from './TheHeaderDropdownNotif'
import TheHeaderDropdownTasks from './TheHeaderDropdownTasks'
import TheHeaderDropdownMssgs from './TheHeaderDropdownMssgs'
import CMenu from './Menu'
import axios from 'axios'
import Vue from "vue";

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    // TheHeaderDropdownNotif,
    // TheHeaderDropdownTasks,
    // TheHeaderDropdownMssgs,
    CMenu
  },
  data: function () {
    return {
      langs: [],
      locale: 'pl',
      timerId: ''
    }
  },
  methods: {
    selectLocale: function (option) {
      localStorage.setItem("locale", option)
      this.$i18n.set(option)
      //location.reload()
      this.$emit('change-locale', option)
    },
    onidle() {
      this.logout();
    },
    onremind(time) {
      this.tokenRefresh();
    },
    logout() {
      clearInterval(this.timerId);
      this.timerId = '';

      let self = this;

      axios.post(this.$apiAdress + '/api/logout-now?token=' + localStorage.getItem("api_token"), {})
        .then(function (response) {
          document.cookie = 'earmon_session=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          console.log('Session end');
        }).catch(function (error) {
        console.log(error);
      });

      axios.post(this.$apiAdress + '/api/logout?token=' + localStorage.getItem("api_token"), {})
        .then(function (response) {
          console.log('Session end');
        }).catch(function (error) {
        console.log(error);
      });
      localStorage.setItem("auth", "");
      localStorage.setItem('api_token', '');
      localStorage.setItem('earmon_session', '');
      localStorage.removeItem('auth');
      localStorage.removeItem('api_token');
      localStorage.removeItem('earmon_session');
      self.$router.push({path: '/login'});
    },
    tokenRefresh() {
      if(localStorage.getItem('auth') == "" || localStorage.getItem('api_token') == ""){
        this.logout();
      }

      if(Vue.prototype.$tokenCheck === true){
        axios.get(this.$apiAdress + '/api/refresh?token=' + localStorage.getItem("api_token"), {})
          .then(() => {
            console.log('Token refreshed');
          }, (error) => {
            this.logout();
          })
      }
    }
  },
  mounted() {
    this.tokenRefresh();
    this.timerId = window.setInterval(() => {
      this.tokenRefresh();
    }, 60000);
    let self = this;
    if (typeof localStorage.locale !== 'undefined') {
      this.locale = localStorage.getItem("locale")
    }
    axios.get(this.$apiAdress + '/api/langlist?token=' + localStorage.getItem("api_token"))
      .then(function (response) {
        self.langs = [];
        for (let i = 0; i < response.data.length; i++) {
          self.langs.push({
            value: response.data[i].short_name,
            label: response.data[i].name
          });
        }
      }).catch(function (error) {
      console.log(error)
      self.$router.push({path: '/login'})
    });


    // const nodes = document.querySelectorAll(".breadcrumb-item");
    // if(nodes != undefined && nodes !=''){
    //
    //   if (nodes.length > 0) {
    //     const first = nodes[0].firstElementChild;
    //     let self = this;
    //
    //     first.href = "#/dashboard";
    //     first.style.display = 'none';
    //     //self.$router.push({path: 'dashboard'});
    //     console.log(first);
    //   }
    //
    //
    // }

  },
}

</script>
