<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
<!--          <img-->
<!--            src="img/avatars/6.jpg"-->
<!--            class="c-avatar-img "-->
<!--          />-->
          <CIcon name="cil-settings" />
          <i class="fa fa-cog"></i>
        </div>
      </CHeaderNavLink>
    </template>
<!--    <CDropdownHeader tag="div" class="text-center" color="light">-->
<!--      <strong>Konto</strong>-->
<!--    </CDropdownHeader>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-bell"/>-->
<!--      Updates-->
<!--      <CBadge color="info" class="ml-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-envelope-open"/>-->
<!--      Wiadomości-->
<!--      <CBadge color="success" class="ml-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-task"/>-->
<!--      Taski-->
<!--      <CBadge color="danger" class="ml-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-comment-square"/>-->
<!--      Comments-->
<!--      <CBadge color="warning" class="ml-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>{{ $t('forms.transl67')}}</strong>
    </CDropdownHeader>
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-user"/>-->
<!--      Profil-->
<!--    </CDropdownItem>-->
    <CDropdownItem @click="myprofile()">
      <CIcon name="cil-user"/>
      {{ $t('forms.transl68')}}
    </CDropdownItem>
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-dollar"/>-->
<!--      Płatności-->
<!--      <CBadge color="secondary" class="ml-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-file"/>-->
<!--      Projekt-->
<!--      <CBadge color="primary" class="ml-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
    <CDropdownDivider/>
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-shield-alt"/>-->
<!--      Lock Account-->
<!--    </CDropdownItem>-->
    <CDropdownItem @click="logout()">
      <CIcon name="cil-lock-locked"/>
      {{ $t('forms.transl288')}}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      itemsCount: 42,
    }
  },
  methods: {
    logout() {
      let self = this;
      axios.post(this.$apiAdress + '/api/logout?token=' + localStorage.getItem("api_token"), {})
        .then(function (response) {
          localStorage.setItem("auth", "");
          localStorage.setItem('api_token', '');
          self.$router.push({path: '/login'});
        }).catch(function (error) {
        console.log(error);
      });
    },
    myprofile()
    {
      let self = this;
      this.$router.push({path:  '/myprofile'});
    }
  }
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
